@import '@stream-io/video-react-sdk/dist/css/styles.css';

.str-video {
    height: calc(100vh - 64px);
    width: 100%;
    --str-video__background-color5: #000000;
    --str-video__background-color6: #000000;
    --str-video__background-color7: #000000;
}

.str-video__participant-view {
    max-width: 100%;
}